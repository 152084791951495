import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { formatFileSize } from "../../../utils";
import { toast } from "react-toastify";
import Pagination from "../../constant/Pagination";
import {
  getDocumentList,
  createDocument,
  updateDocument,
  updateDocumentStatus,
} from "../../../services/SIService";
import { useNavigate } from "react-router-dom";
import TableLoaderEffect from "../../constant/TableLoader";


function DocumentPage({ userRole }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [confirmMSG, setConfirmMSG] = useState("");
  const [documentInput, setDocumentInput] = useState({
    document_number: "",
    description: "",
    file: "",
  });
  const [cocumentInputError, setDocumentInputError] = useState({
    document_number: false,
    description: false,
    file: false,
  });
  const [isModalOpen, setIsModalOpen] = useState({ open: false, type: "Add" });
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [total, setTotal] = useState(0);
  const recordsPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchQuery.length > 0) {
      setIsLoading(true);
    }
    getDocumentList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setIsSearchLoading(false);
      });
  }, [currentPage, searchQuery]);

  const toggleModal = (type = "Add", item = null) => {
    let open = isModalOpen.open;

    if (type === "Add") {
      open = !open;
    } else if (type === "Edit" && item) {

      setSelectedDocument(item)

      setDocumentInput({
        id: item.id,
        document_number: item.document_number,
        description: item.description,
        file: "",
        file_data: item.attachment,
      });
      open = true;
    }
    if (isModalOpen.open && item === null) {
      setDocumentInput({
        document_number: "",
        description: "",
        file: "",
      });

      setDocumentInputError({
        document_number: false,
        description: false,
        file: false,
      });

      setSelectedDocument(null)
    }
    setIsModalOpen({ type, open });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setIsSearchLoading(true);
    setCurrentPage(1);
    setSearchQuery(value);
  };


  const toggleArchiveModal = () => {
    if (showArchiveModal) {
      setSelectedDocument(null)
    }
    setShowArchiveModal(!showArchiveModal);
  };



  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    if (filelist.length) {
      const file = filelist[0];
      setDocumentInput({ ...documentInput, file: file });
      setDocumentInputError({ ...cocumentInputError, file: false })
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedSirInput = { ...documentInput, [name]: value };
    const updatedSirInputError = {
      ...cocumentInputError,
      [name]: value.trim() === "",
    };
    setDocumentInput(updatedSirInput);
    setDocumentInputError(updatedSirInputError);
  };


  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const complianceInputErrorCopy = { ...cocumentInputError };
    let hasError = false;
    Object.keys(documentInput).forEach((key) => {
      if (documentInput[key] === "" && key !== "unit_id") {
        if (key === "file" && isModalOpen.type === "Edit") {
          return
        }
        complianceInputErrorCopy[key] = true;
        hasError = true;
      }
    });
    if (hasError) {
      setDocumentInputError(complianceInputErrorCopy);
      return;
    }

    if (isModalOpen.type === "Add") {
      try {
        setIsSubmitLoading(true);
        const reqBody = new FormData();
        Object.entries(documentInput).forEach(([fieldName, value]) => {
          reqBody.append(fieldName, value);
        });
        const resp = await createDocument(reqBody);
        const { message, results } = resp.data;
        toast.success(message);
        setData([results, ...data]);
        toggleModal();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitLoading(false);
      }
    } else {
      try {
        setIsSubmitLoading(true);
        const reqBody = new FormData();
        Object.entries(documentInput).forEach(([fieldName, value]) => {
          if (fieldName !== "file_data") {
            reqBody.append(fieldName, value);
          }
        });
        const resp = await updateDocument(reqBody);
        const { results, message } = resp.data;
        setData(data.map((item) => item.id === results.id ? { ...results } : item));
        toast.success(message);
        toggleModal();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitLoading(false);
      }
    }
  };


  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      const resp = await updateDocumentStatus({
        id: selectedDocument.id,
        archive: !selectedDocument.is_archive,
        archive_reason: confirmMSG,
      });
      toast.success(resp.data.message);
      setConfirmMSG("");
      if (!data.is_archive) {
        navigate('/archive-document')
      } else {
        setData({ ...data, is_archive: !data.is_archive });
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
    toggleModal(false);
  };

  const viewPDFFile =  (id) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/document_file_download/${id}`
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error fetching or opening file:', error);
    }
  };

  return (
    <>
      <div>
        <div className="card-body p-0">
          <div className="d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
            <h4 className="heading mb-3 mb-md-0">DOCUMENT</h4>
            <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
              <div className="me-3 mb-md-0">
                <div className="input-group tp-input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchQuery}
                    aria-describedby="basic-search"
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-text" id="basic-search">
                    {isSearchLoading && <Spinner variant="primary" size="sm" />}
                    {!isSearchLoading && searchQuery.length > 0 && (
                      <span
                        className="c-pointer"
                        onClick={() => {
                          setCurrentPage(1);
                          setSearchQuery("");
                        }}
                      >
                        <i className="fa-solid fa-xmark text-primary"></i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {userRole !== "User" && (
                <button
                  className="btn btn-primary mt-3 mt-md-0"
                  onClick={() => toggleModal()}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            <div
              id="employee-tbl_wrapper"
              className="dataTables_wrapper no-footer"
            >
              <table
                id="empoloyees-tblwrapper"
                className="table ItemsCheckboxSec dataTable no-footer mb-0 table-striped"
              >
                <thead>
                  <tr>
                    <th>Document Number</th>
                    <th>Description</th>
                    <th>Attachment</th>
                    {userRole === "SuperAdmin" && <th>Action</th>}

                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <TableLoaderEffect colLength={userRole === "User" ? 5 : 6} />
                  ) : (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.document_number}</td>
                        <td>{item.description}</td>
                        <td>
                          {item.attachment ? (
                            <Button
                              variant="btn btn-primary tp-btn btn-sm"
                              onClick={() => viewPDFFile(item.id)}
                            >
                              <span className="d-flex align-items-center">
                                <i className="fa-solid fa-download me-2"></i>
                                <span>Download Attachment</span>
                              </span>
                            </Button>
                          ) : null}
                        </td>
                        <td>
                          {userRole === "SuperAdmin" && (
                            <>
                              <Button
                                onClick={() => toggleModal("Edit", item)}
                                variant="btn btn-primary tp-btn btn-sm"
                              >
                                <i className="fa-regular fa-pen-to-square me-1"></i>
                                Edit
                              </Button>
                              <Button
                                onClick={() => navigate(`/si/${item.id}`)}
                                variant="btn btn-primary tp-btn btn-sm"
                              >
                                <i className="fa-solid fa-clock-rotate-left"></i> View Log
                              </Button>
                              <Button
                                onClick={() => {
                                  setSelectedDocument(item)
                                  toggleArchiveModal();
                                }}
                                variant="btn btn-danger tp-btn btn-sm"
                              >
                                <i className="fa-solid fa-box-archive me-1"></i>
                                {item?.is_archive ? "Unarchive" : "Archive"}
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPage}
              recordsPage={recordsPage}
              dataLength={total}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </div>
      {/* card end */}

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={isModalOpen.open && isModalOpen.type === "Add"}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Add Document
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-12">
                  <label className="form-label">
                    Document Number<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    autoFocus
                    id="document_number"
                    name="document_number"
                    className={`form-control mb-3 ${cocumentInputError.document_number ? "is-invalid" : ""
                      }`}
                    placeholder="Enter Documnet Number"
                    value={documentInput.document_number}
                    onChange={handleChange}
                  />
                  {cocumentInputError.document_number && (
                    <div className="text-danger mb-3">
                      Document No. is required.
                    </div>
                  )}
                </div>

                <div className="col-xl-12">
                  <label htmlFor="description" className="form-label">
                    Description<span className="text-danger">*</span>
                  </label>
                  <textarea
                    name={"description"}
                    id={"description"}
                    style={{ minHeight: "100px" }}
                    className={`form-control mb-3 ${cocumentInputError.description ? "is-invalid" : ""
                      }`}
                    placeholder={"Enter Description"}
                    onChange={handleChange}
                    value={documentInput.description}
                  ></textarea>
                  {cocumentInputError.description && (
                    <div className="text-danger mb-3">Description is required.</div>
                  )}
                </div>
                <div className="col-xl-12">
                  <label htmlFor="pdfFile" className="form-label">
                    Attachment<span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    className={`form-control ${cocumentInputError.file && "is-invalid"
                      }`}
                    id="file"
                    name="file"
                    onChange={handleFileInputChange}
                  />
                  {cocumentInputError.file && (
                    <div className="text-danger mb-3">
                      File is required.
                    </div>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner size="sm" className="me-2" />
                    Creating...
                  </span>
                ) : (
                  "Create"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>

      {/* edit modal */}

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={isModalOpen.open && isModalOpen.type === "Edit"}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Edit Compliance
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-12">
                  <label className="form-label">
                    Document Number<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    autoFocus
                    id="document_number"
                    name="document_number"
                    className={`form-control mb-3 ${cocumentInputError.document_number ? "is-invalid" : ""
                      }`}
                    placeholder="Enter Documnet Number"
                    value={documentInput.document_number}
                    onChange={handleChange}
                  />
                  {cocumentInputError.document_number && (
                    <div className="text-danger mb-3">
                      Document No. is required.
                    </div>
                  )}
                </div>

                <div className="col-xl-12">
                  <label htmlFor="description" className="form-label">
                    Description<span className="text-danger">*</span>
                  </label>
                  <textarea
                    name={"description"}
                    id={"description"}
                    style={{ minHeight: "100px" }}
                    className={`form-control mb-3 ${cocumentInputError.description ? "is-invalid" : ""
                      }`}
                    placeholder={"Enter Description"}
                    onChange={handleChange}
                    value={documentInput.description}
                  ></textarea>
                  {cocumentInputError.description && (
                    <div className="text-danger mb-3">Description is required.</div>
                  )}
                </div>

                <div className="col-xl-12">
                  <label htmlFor="file" className="form-label">
                    Attachment
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".pdf"
                  />
                  {documentInput.file_data ? (
                    <div className="text-warning mt-1 fst-italic">
                      Existing File {documentInput.file_data.name}
                      <span className="ms-2 fw-bold">
                        {formatFileSize(documentInput.file_data.size)}
                      </span>
                    </div>
                  ) : null}
                </div>

              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner size="sm" className="me-2" />
                    Updating...
                  </span>
                ) : (
                  "Update"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal modal-sm fade"
        id="archiveModal"
        show={showArchiveModal}
        centered={true}
        onHide={toggleArchiveModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              {selectedDocument?.is_archive ? "Unarchive" : "Archive"} Document
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleArchiveModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleOnSubmit}>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="confirmMSG" className="form-label">
                    {selectedDocument?.is_archive
                      ? "Type 'confirm' to unarchive"
                      : "Reason For Archive"}
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="confirmMSG"
                    name="confirmMSG"
                    placeholder={
                      selectedDocument?.is_archive
                        ? "Type 'confirm' to unarchive"
                        : "Reason For Archive"
                    }
                    value={confirmMSG}
                    onChange={(e) => setConfirmMSG(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={
                  isSubmitLoading ||
                  confirmMSG.trim() === "" ||
                  (selectedDocument?.is_archive
                    ? confirmMSG.toLocaleLowerCase() !== "confirm"
                    : false)
                }
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner variant="light" size="sm" className="me-2" />
                    {selectedDocument?.is_archive ? "Unarchive" : "Archive"}
                  </span>
                ) : selectedDocument?.is_archive ? (
                  "Unarchive"
                ) : (
                  "Archive"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "User",
  };
};
export default connect(mapStateToProps)(DocumentPage);
